@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap');

/* General styles */
body {
  font-family: Arial, sans-serif;
  background-color: #000;
  color: #fff;
  margin: 0;
  padding: 0;
  height: 100vh;
}

/* Typography styles */
h1 {
  color: #624CAB;
  font-size: 60px;
  font-family: 'Permanent Marker';
}

h2 {
  font-size: 24px;
  margin: 0 0 10px;
  color: #D4C1EC;
}

h2 a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

h2 a:hover {
  text-decoration: none;
}

p {
  font-size: 18px;
  margin: 5px 0;
}

a {
  font-size: 16px;
  color: #624CAB;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100vw;
  margin: 0 auto;
  padding: 10px;
  box-sizing: border-box;
  height: 100%;
}

/* Header styles */
.header {
  display: flex;
  flex-direction: row;
}

/* Filters */
.filters {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  gap: 10px;
  font-weight: bold;
  font-size: 18px;
}

/* Decade filter styles */
.decade-filter {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.decade-filter label {
  margin-bottom: 0px;
}

.decade-filter select {
  padding: 8px;
  font-size: 16px;
  border-radius: 5px;
  background-color: #fff;
  color: #000;
  border: 3px solid #ccc;
  width: 90px;
}

/* Rating filter styles */
.rating-filter {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.rating-filter label {
  margin-bottom: 0px;
}

.rating-filter select {
  padding: 8px;
  font-size: 16px;
  border-radius: 5px;
  background-color: #fff;
  color: #000;
  border: 3px solid #ccc;
  width: 90px;
}

/* Input styles */
input {
  width: 100%;
  padding: 10px 15px;
  font-size: 18px;
  border: 2px solid #ccc;
  border-radius: 5px;
  outline: none;
  background-color: #fff;
  color: #000;
}

/* Search bar styles */
.search-bar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
}

.search-input-container {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
}

input[type='text'] {
  border: none;
  outline: none;
  width: 100%;
  padding: 10px;
  font-size: 1.2rem;
  background-color: transparent;
}

.clear-search-button {
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  padding-right: 20px;
  color: #624CAB;
}

.clear-search-button:hover {
  transform: scale(150%);
  color: black;
}

/* Button styles */
.buttons {
  display: flex;
  flex-direction: row;
  gap: 15px;
  width: 100%;
}

button {
  display: block;
  width: 33%; 
  padding: 10px 15px;
  font-size: 18px;
  background-color: #624CAB;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

button:hover:not(:disabled) {
  background-color: #D4C1EC;
  color: black;
}

/* Results styles */
.results {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
  width: 100%;
}

/* Result item styles */
.result {
  background-color: #1c1c1c;
  padding: 25px;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 15px;
  margin-bottom: 20px;
}

.result img {
  flex-shrink: 0;
  width: 150px;
  height: 225px;
  object-fit: cover;
  border-radius: 5px;
}

.result-content {
  flex-grow: 1;
}

/* Watch trailer button styles */
.watch-trailer-button {
  background-color: #624CAB;
  border: none;
  padding: 8px 12px;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  text-decoration: none;
  outline: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  width: 100%;
}

.watch-trailer-button:hover:not(:disabled) {
  background-color: #D4C1EC;
  color: black;
}

.watch-trailer-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

/* Error message styles */
.error-message {
  color: red;
  font-weight: bold;
}

/* Overlay styles */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Loader styles */
.loader {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropbtn {
  background-color: #4CAF50;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropdown-content {
  display: none;
  position: absolute;
  z-index: 1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

/* Media queries */
@media (max-width: 767px) {
  input,
  .buttons {
    width: 100%;
  }
  .search-bar-container {
    width: 90%;
  }
}

@media (min-width: 768px) {
  input,
  .buttons {
    width: 80%;
  }
  .search-bar-container {
    width: 90%;
  }
}

@media (min-width: 992px) {
  input,
  .buttons {
    width: 60%;
  }
  .search-bar-container {
    width: 80%;
  }
  .result {
    width: calc(50% - 10px);
  }
}

@media (min-width: 1200px) {
  input,
  .buttons {
    width: 50%;
  }
  .search-bar-container {
    width: 60%;
  }
  .result {
    width: calc(33% - 12px);
  }
}